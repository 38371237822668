* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: #043442;
    background: #E5E7E7;
    height: 100vh;
  }
  .mainContainer{
   display: flex;
  }

  .topPane {
    width: 23%; 
    height: 100%;
    position: fixed;
    z-index: 1; /* Stay on top */
    top: 0%; /* Stay at the top */
    padding-left: 1%;
    color: #E5E7E7;
    background-color: #043442; /*Dark Teal*/ 
    padding-top: 6px; /* Place content 60px from the top */ 
  }

  .mainPane{
    margin-left: 25%;
  }

  a:link { 
    color: #E5E7E7;
    text-decoration: none; 
  }

  a:visited {
    color: #E5E7E7;
    text-decoration: none;
  }


